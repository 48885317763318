import footer from "./assets/footer.jpeg"
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useState, useEffect } from 'react';
function App() {

  const [price,setPrice] = useState(0)
  const [userCount,setUserCount] = useState(0)
  const [countTrades,setTrades] = useState(0)
  const [totalInvestment,setTotalInvestment] = useState(0)
    // eslint-disable-next-line
  const [totalOut,setTOTALOUT] = useState(0)
  const [dailyTrades,setDailyTrades] = useState(0)






    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.dexscreener.com/latest/dex/tokens/8ygQonfsFzuqS5omS45pbEnuteJVoFKobPp1hY4BT7VY'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        console.log(responseData.pairs[0].priceUsd)
        setPrice(responseData.pairs[0].priceUsd)

      } catch (error) {
    
      } 
    };


    const TOTALUSERS = async () => {
      try {
        const response = await fetch(
          'https://dumbmoney.pro:5000/api/users'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
       

        // const balances = await Promise.all(responseData.map(user => getUserBalance(user[1])));
        // setUserBalances(balances);

        // const userTrades = await Promise.all(responseData.map(user => USERTRADES(user[1])));
        // setUserTrades(userTrades);

        // const getPNL = await Promise.all(responseData.map(user => USERPNL(user[1])));
        // setGETPNL(getPNL);

        // const getTIME = await Promise.all(responseData.map(user => USER_LAST_TRADE(user[1])));
        // setGETTIME(getTIME);

        console.log(responseData.length)
        setUserCount(responseData.length)
    

      } catch (error) {
      
      } 
    };



    const TOTALTRADES = async () => {
      try {
        const response = await fetch(
          'https://dumbmoney.pro:5000/api/trades'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        console.log(responseData.length)
        setTrades(responseData.length)
    
     
      } catch (error) {
      
      } 
    };

      const DAILYTOTALTRADES = async () => {
      try {
        const response = await fetch(
          'https://dumbmoney.pro:5000/api/hours'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        console.log(responseData.length)
        setDailyTrades(responseData.length)
    
     
      } catch (error) {
      
      } 
    };










    const TOTALIN = async () => {
      try {
        const response = await fetch(
          'https://dumbmoney.pro:5000/api/trades/buy'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        console.log(responseData.total_buy_sum)
        setTotalInvestment(responseData.total_buy_sum)
    

      } catch (error) {
     
      }
    };


    const TOTALOUT = async () => {
      try {
        const response = await fetch(
          'https://dumbmoney.pro:5000/api/trades/sell'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        console.log(typeof responseData.total_buy_sum)
        setTOTALOUT(responseData.total_buy_sum)
    
    
      } catch (error) {
         
      } 
    };    

  useEffect(() => {
    fetchData();
    TOTALUSERS();
    TOTALTRADES();
    DAILYTOTALTRADES();
    TOTALIN()
    TOTALOUT()
  }, // eslint-disable-next-line
  []);

  return (
   <>
   <div className='container'>
   <div className='row'>
    <div className='col-sm-4'>
     <div className='card text-center'>
      <div className='card-body bg-dark text-white'>
        <h3>Users</h3>
        <h1>{userCount}</h1>
      </div>
     </div>
    </div>

    <div className='col-sm-4'>
    <div className='card text-center'>

        <div className='card-body bg-dark text-white'>
      <h3>Daily Trades</h3>
      <h1>{dailyTrades}</h1>
      </div></div>
    </div>

    <div className='col-sm-4'>
    <div className='card text-center'>

        <div className='card-body bg-dark text-white'>
      <h3>Total Trades</h3>
      <h1>{countTrades}</h1>
      </div></div>
    </div>




   </div>

   <br />
   <div className='row'>
   
   <div className='col-sm-4'>
    <div className='card text-center'>

        <div className='card-body bg-dark text-white'>
      <h3>Total Invested</h3>
      <h1>{Number(totalInvestment).toFixed(5)} SOL</h1>
      </div></div>
    </div>

   <div className='col-sm-4'>
    <div className='card text-center'>

        <div className='card-body bg-dark text-white'>
      <h3>Price</h3>

      <h1>
  {Number(price).toFixed(5)} USD
</h1>

 </div></div>
    </div>

    <div className='col-sm-4'>
    <div className='card text-center'>

        <div className='card-body bg-dark text-white'>
      <h3>DOGBOT MC</h3>
      <h1>{Number(46999999 * price).toFixed(2)} USD</h1>
      </div></div>
    </div>
   </div>
   <br />

   {/* <table className='table table-dark text-center'>
    <thead>
      <tr>
        <th>Wallet</th>
        <th>Balance</th>
        <th>Total Trades</th>
        <th>PNL</th>
        <th>LAST TRADE</th>
      </tr>
    </thead>
    <tbody>
    {userMapping.map((user, index) => (
                        <tr key={index}>
                          {
                          // eslint-disable-next-line
                          <td><a href={`https://solscan.io/account/${user[1]}`} target="_blank" style={{color:"#fff",textDecoration:"none"}}>{shortenSolanaAddress(user[1])}</a></td>
                          }
                            <td>{Number(userBalances[index]).toFixed(5)} SOL</td>
                            <td>{userTrades[index]}</td>
                            <td>{Number(GETPNL[index]) > 0? <span style={{color:"lime"}}>{Number(GETPNL[index]).toFixed(5)}</span> : <span style={{color:"red"}}>{Number(GETPNL[index]).toFixed(5)}</span>}</td>
                            <td>{Number(getTIME[index]) === 0? <>No Activity</> : <>{getTIME[index]}</>}</td>
                        </tr>
                    ))}
    </tbody>
   </table> */}
   </div>
   <center>
   <img src={footer} alt="footer" className='img-fluid' width={600}/>
   </center>


  
   </>
  );
}

export default App;
